<template >
    <div>
      <div class="app-modal__box">
        <div class="app-modal__in">
          <div class="app-modal__header d-flex f-between p-5 m-title-modal">
            <p class="large--title m-0"> {{ $t("message.new_m", { m: $t("message.price_list"), }) }}</p>
            <div>
               <crm-store-update-close
                  :permission="$options.name"
                  :button_type="'store'"
                  :loading="loadingButton"
                  @c-submit="submit(true)"
                  @c-close="close()"
              ></crm-store-update-close>
            </div>
          </div>
        </div>
        <!-- app-modal__header end -->
        <div class="p-5 mt-5">
            <el-tabs v-model="activeTabName" type="border-card">
                <el-tab-pane name="add">
                    <template slot="label">
                        <span class="mm_tab_label_font">{{ $t('message.add') }} {{ $t('message.price_list') }}</span>
                    </template>
                    <el-form ref="form" :model="form" :rules="rules" label-position="top">
                        <el-row :gutter="20">
                            <el-col :span="24">
                                <el-form-item
                                prop="name"
                                :label="$t('message.name')"
                                >
                                <el-input
                                    :placeholder="$t('message.name')"
                                    v-model="form.name"
                                ></el-input>
                                </el-form-item>     
                            </el-col>

                            <el-col :span="12">
                                <el-form-item
                                prop="begin_date"
                                :label="$t('message.begin_date')"
                                >
                                    <el-date-picker
                                        class="w-100"
                                        format="yyyy-MM-dd" 
                                        value-format="yyyy-MM-dd"
                                        v-model="form.begin_date"
                                        type="date"
                                        :placeholder="$t('message.begin_date')">
                                    </el-date-picker>
                                </el-form-item>     
                            </el-col>
                            <el-col :span="12">
                                <el-form-item
                                prop="end_date"
                                :label="$t('message.end_date')"
                                >
                                    <el-date-picker
                                        class="w-100"
                                        format="yyyy-MM-dd" 
                                        value-format="yyyy-MM-dd"
                                        v-model="form.end_date"
                                        type="date"
                                        :placeholder="$t('message.end_date')">
                                    </el-date-picker>
                                </el-form-item>     
                            </el-col>
                            <el-col :span="8">
                                <el-form-item  :label="$t('message.filials')" prop="filial_ids">
                                    <multi-select-filials
                                        v-model="form.filial_ids"
                                        :filial_ids="form.filial_ids"
                                        size="large"
                                    ></multi-select-filials>
                                </el-form-item>     
                            </el-col>
                            <el-col :span="8">
                                <el-form-item
                                prop="active"
                                :label="$t('message.active')"
                                >
                                    <el-switch
                                        v-model="form.active"
                                        :active-text="$t('message.active')"
                                        :inactive-text="$t('message.inactive')">
                                    </el-switch>
                                </el-form-item>     
                            </el-col>
                            <el-col :span="8">
                                <el-form-item
                                prop="is_default"
                                :label="$t('message.is_default')"
                                >
                                    <el-checkbox v-model="form.is_default" :label="$t('message.is_default')" border></el-checkbox>
                                </el-form-item>     
                            </el-col>
                            
                        </el-row>
                        <div class="text-right">
                            <el-button @click="savePriceList()" :loading="loadingButton" type="primary" icon="el-icon-plus">{{ $t('message.add') }}</el-button>
                        </div>
                    </el-form>
                </el-tab-pane>
                <el-tab-pane name="services">
                    <template slot="label">
                        <span class="mm_tab_label_font">{{ $t('message.price_list') }} {{ $t('message.services') }} </span>
                    </template>
                    <div v-loading="loadingData">
                        <div>
                            <el-row :gutter="20">
                                <el-col :span="24">
                                    <span class="mm_select_label">{{$t('message.price_list')}}</span>
                                </el-col>
                                <el-col :span="16">
                                    <select-price-list
                                        class="mb-2"
                                        :size="'large'"
                                        :placeholder="$t('message.price_list')"
                                        :id="price_list_id"
                                        @input="updatePriceListId"
                                        :auto_select_default="false"
                                        v-model="price_list_id"
                                        @updateSelectedPriceList="updateSelectedPriceList"
                                    >
                                    </select-price-list>
                                </el-col>
                                <el-col v-if="price_list_id" :span="8">
                                    <el-button 
                                        v-if="selectedPriceList && selectedPriceList.approved_obj && selectedPriceList.approved == 3" 
                                        @click="sendToApprovePriceList()" 
                                        :loading="loadingButton" 
                                        class="w-100" type="primary"
                                    >
                                        {{ $t('message.send_to_approve') }}
                                    </el-button>
                                    <el-button
                                        v-if="checkApproved"
                                        @click="approvePriceList()" 
                                        :loading="loadingButton" 
                                        class="w-100" 
                                        type="primary"
                                    >
                                        {{ $t('message.approve') }}
                                    </el-button>
                                </el-col>
                            </el-row>
                            <div class=" pt-4 pb-4">
                                <span class="mm_approved_by_users">{{ $t('message.inspectors') }}: </span>
                                <el-row class="mt-4" :gutter="20">
                                    <el-col v-if="selectedPriceList && selectedPriceList.getInspectors" v-for="user in selectedPriceList.getInspectors" :span="8">
                                        {{ user.name }} : {{ (selectedPriceList.approved_by && selectedPriceList.approved_by.includes(user.id)) ? $t('message.approved') : $t('message.for_checking') }}
                                    </el-col>
                                </el-row>
                            </div>

                        </div>
                        

                        <el-table
                            class="w-100"
                            :data="serviceList"
                            @selection-change="handleSelectionChange"
                            border
                            stripe
                            style="width: 100%">
                            <el-table-column
                                type="selection"
                                width="55">
                            </el-table-column>
                            <el-table-column
                                prop="id"
                                :label="$t('message.n')"
                                width="80">
                            </el-table-column>
                            <el-table-column
                                prop="name"
                                :label="$t('message.name')">
                            </el-table-column>
                            <el-table-column width="150">
                                <template slot="header" slot-scope="scope">
                                    {{ $t('message.price') }}
                                    <el-input
                                        size="medium"
                                        :placeholder="$t('message.price')"
                                        v-model="form.money_amount"
                                        @input="updateSelectedMoneyAmount"
                                    ></el-input>
                                </template>
                                <template slot-scope="scope">
                                    <el-input
                                        size="medium"
                                        :placeholder="$t('message.price')"
                                        v-model="scope.row.money_amount"
                                    ></el-input>
                                </template>
                            </el-table-column>
                            <el-table-column>
                                <template slot="header" slot-scope="scope">
                                    {{ $t('message.currency') }}
                                    <select-currency
                                        :size="'medium'"
                                        :id="form.currency_id"
                                        v-model="form.currency_id"
                                        @input="updateSelectedCurrency"
                                    ></select-currency>
                                </template>
                                <template slot-scope="scope">
                                    <div class="d-flex w-100" style="justify-content: space-between">
                                        <select-currency
                                            v-if="selectedRowIndex >= 0 && selectedRowIndex == scope.$index" 
                                            class="w-100"
                                            :size="'medium'"
                                            :id="scope.row.currency_id"
                                            v-model="scope.row.currency_id"
                                            @input="updateCurrency($event, scope.$index)"
                                        ></select-currency>
                                        <span v-else>
                                            {{scope.row.currency ? scope.row.currency.symbol : ''}}
                                        </span>
                                        <el-button @click="changeRowCurrency(scope.row, scope.$index)" class="ml-2" type="primary" size="small" icon="el-icon-refresh"></el-button>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>

                </el-tab-pane>
            </el-tabs>
        </div>
        <!-- end app-modal__body -->
      </div>
    </div>
  </template>
  
<script>
  import { mapGetters, mapActions } from "vuex";
  import drawer from "@/utils/mixins/drawer";
  import form from "@/utils/mixins/form";
  import list from "@/utils/mixins/child_list";
  import selectPriceList from "@/components/inventory/select-service-price-list";
  import selectCurrency from "@/components/inventory/select-currency";
  import MultiSelectFilials from '@/components/multiSelects/multi-select-filials';
  
  export default {
    
    mixins: [list, form, drawer],
    components: {
        selectPriceList,
        selectCurrency,
        MultiSelectFilials
    },
    data() {
      return {
        activeTabName: 'services',
        serviceList: [],
        loadingData: true,
        selectedServiceList: [],
        selectedRowIndex: '',
        price_list_id: null,
        selectedPriceList: {},
      };
    },
    created() {},
    computed: {
        ...mapGetters({
            authUser: "auth/user",
            currencies: "currency/inventory",
            rules: "servicePriceList/rules",
            model: "servicePriceList/model",
            list: "productServices/service_list",
            columns: "servicePriceList/columns",
            pagination: "productServices/pagination",            
            filter: "productServices/filter",
            sort: "productServices/sort",
            mode: "MODE",
            treeInventory: "productServices/treeInventory",
        }),
        checkApproved(){
            if(this.selectedPriceList && this.selectedPriceList.approved && this.selectedPriceList.inspectors){
                if(this.selectedPriceList.inspectors.includes(this.authUser.id) && (this.selectedPriceList.approved == 2 || (this.selectedPriceList.approved == 1 && (!this.selectedPriceList.approved_by || !this.selectedPriceList.approved_by.includes(this.authUser.id))))){
                    return true;
                }
            }
            return false;
        }
    },
    methods: {
        ...mapActions({
            storePriceList: "servicePriceList/store",
            updatePriceList: "servicePriceList/inventory",
            save: "servicePriceListPrice/store",
            updateList: "productServices/index",
            setPagination: "productServices/setPagination",
            updateSort: "productServices/updateSort",
            updateFilter: "productServices/updateFilter",
            updateColumn: "productServices/updateColumn",
            updatePagination: "productServices/updatePagination",
            show: "productServices/show",
            empty: "productServices/empty",
            delete: "productServices/destroy",
            refreshData: "productServices/refreshData",
            updateServiceTree: "productServices/serviceCategoryTree",
            sendForConfirmation: "servicePriceList/sendForConfirmation",
            confirmPriceList: "servicePriceList/confirmPriceList",
            updateInventory: 'servicePriceList/inventory'
        }),
        updateSelectedPriceList(status){
            this.selectedPriceList = status;
        },
        handleSelectionChange(val) {
            this.selectedServiceList = val.map(el => el.id);
        },
        updateCurrency(val, index){
            let currency = this.currencies.find(el => el.id == val);
            this.serviceList[index].currency = currency;
        },

        updateSelectedMoneyAmount(val){
            this.serviceList.forEach(element => {
                if(this.selectedServiceList.includes(element.id)){
                    element.money_amount = val;
                }
            });
        },
        updateSelectedCurrency(val){
            let currency = this.currencies.find(el => el.id == val);

            this.serviceList.forEach(element => {
                if(this.selectedServiceList.includes(element.id)){
                    element.currency_id = val;
                    element.currency = currency;
                }
            });
        },
        changeRowCurrency(row, index) {
            this.selectedRowIndex = index;
        },
        savePriceList(){
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    this.loadingButton = true;
                    this.storePriceList(this.form)
                        .then(res => {
                            this.loadingButton = false;
                            this.$alert(res);
                            this.reloadForm();
                            this.updatePriceList();
                        })
                        .catch(err => {
                            this.loadingButton = false;
                            this.$alert(err);
                        });
                }
            });
        },
        async afterOpen(){
            await this.fetchData();
            // await this.updateServiceTree();
        },

        sendToApprovePriceList(){
            if(this.price_list_id){
                this.loadingButton = true;
                this.sendForConfirmation(this.price_list_id)
                    .then(res => {
                        this.loadingButton = false;
                        this.updateInventory();
                        this.parent().listChanged();
                        this.$alert(res);
                    })
                    .catch(err => {
                        this.loadingButton = false;
                        this.$alert(err);
                    });
            }
        },

        approvePriceList(){
            if(this.price_list_id){
                this.loadingButton = true;
                this.confirmPriceList(this.price_list_id)
                    .then(res => {
                        this.loadingButton = false;
                        this.updateInventory();
                        this.parent().listChanged();
                        this.$alert(res);
                    })
                    .catch(err => {
                        this.loadingButton = false;
                        this.$alert(err);
                    });
            }
        },

        fetchData(){
            this.loadingData = true;
            this.updateList({per_page: 10000, page:1, for_price_list: true, price_list_id: this.price_list_id, order: 'desc'})
                .then(res => {
                    this.serviceList = JSON.parse(JSON.stringify(this.list));
                    this.loadingData = false;
                })
                .catch(err => {
                    this.loadingData = false;
                });
        },
        updatePriceListId(val) {
            this.fetchData();
        },
        submit(close = true) {
            this.loadingButton = true;
            let services = this.serviceList.map(el => {
                return {
                    id: el.id,
                    money_amount: el.money_amount,
                    currency_id: el.currency_id,
                }
            })
            let data = {price_list_id: this.price_list_id, services: services}
            this.save(data)
                .then((res) => {
                    this.loadingButton = false;
                    this.$alert(res);
                    this.parent().listChanged();
                })
                .catch((err) => {
                    this.loadingButton = false;
                    this.$alert(err);
                });
        },
        afterLeave() {
            this.price_list_id = null;
            this.serviceList = [];
            this.selectedServiceList = [];
            this.selectedRowIndex = '';
            this.reloadForm();
        }
    },
  };
</script>
<style>
.mm_tab_label_font {
    font-size: 16px;
    font-weight: 700;
}
.mm_approved_by_users {
    font-size: 16px;
    font-weight: 700;
}
</style>
<template >
  <div>
    <div class="app-modal__box">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0"> {{
              $t("message.edit_m", {
                m: $t("message.servicePriceList"),
              })
            }}</p>
          <div>
             <crm-store-update-close
                :permission="$options.name"
                :button_type="'store'"
                :loading="loadingButton"
                @c-submit="submit(true)"
                @c-close="close()"
            ></crm-store-update-close>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->
      <div class="app-modal__body p-5 pb-0" v-loading="loadingData">
        <div class="timeline-items__right rounded-sm w-100 p-4">
          <el-form ref="form" :model="form" :rules="rules" label-position="top">
              <el-row :gutter="20">
                  <el-col :span="24">
                      <el-form-item
                      prop="name"
                      :label="$t('message.name')"
                      >
                      <el-input
                          :placeholder="$t('message.name')"
                          v-model="form.name"
                      ></el-input>
                      </el-form-item>     
                  </el-col>

                  <el-col :span="12">
                      <el-form-item
                      prop="begin_date"
                      :label="$t('message.begin_date')"
                      >
                          <el-date-picker
                              class="w-100"
                              format="yyyy-MM-dd" 
                              value-format="yyyy-MM-dd"
                              v-model="form.begin_date"
                              type="date"
                              :placeholder="$t('message.begin_date')">
                          </el-date-picker>
                      </el-form-item>     
                  </el-col>
                  <el-col :span="12">
                      <el-form-item
                      prop="end_date"
                      :label="$t('message.end_date')"
                      >
                          <el-date-picker
                              class="w-100"
                              format="yyyy-MM-dd" 
                              value-format="yyyy-MM-dd"
                              v-model="form.end_date"
                              type="date"
                              :placeholder="$t('message.end_date')">
                          </el-date-picker>
                      </el-form-item>     
                  </el-col>
                  <el-col :span="12">
                      <el-form-item  :label="$t('message.filials')" prop="filial_ids">
                          <multi-select-filials
                              v-model="form.filial_ids"
                              :filial_ids="form.filial_ids"
                              size="large"
                          ></multi-select-filials>
                      </el-form-item>     
                  </el-col>
                  <el-col :span="12">
                    <el-form-item prop="inspectors" :label="$t('message.inspectors')">
                        <multi-select-users
                            v-model="form.inspectors"
                            :user_ids="form.inspectors"
                            :size="'large'"                        
                        >
                        </multi-select-users>
                    </el-form-item>
                  </el-col>
              </el-row>

              <el-row :gutter="20">
                  <el-col :span="8">
                      <el-form-item
                      prop="active"
                      :label="$t('message.active')"
                      >
                          <el-switch
                              v-model="form.active"
                              :active-text="$t('message.active')"
                              :inactive-text="$t('message.inactive')">
                          </el-switch>
                      </el-form-item>     
                  </el-col>
                  <el-col :span="8">
                      <el-form-item
                      prop="is_default"
                      :label="$t('message.is_default')"
                      >
                          <el-checkbox v-model="form.is_default" :label="$t('message.is_default')" border></el-checkbox>
                      </el-form-item>     
                  </el-col>
                  
              </el-row>
          </el-form>
        </div>
      </div>
      <!-- end app-modal__body -->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import show from "@/utils/mixins/show";
import MultiSelectFilials from '@/components/multiSelects/multi-select-filials';
import MultiSelectUsers from '@/components/multiSelects/multi-select-users';

export default {
    mixins: [form, drawer, show],
    components: {
      MultiSelectFilials,
      MultiSelectUsers
    },
    name: "servicePriceList",
    data() {
        return {};
    },
    created() {},
    computed: {
        ...mapGetters({
            rules: "servicePriceList/rules",
            model: "servicePriceList/model",
            columns: "servicePriceList/columns",
        }),
    },
    methods: {
        ...mapActions({
            update: "servicePriceList/update",
            show: "servicePriceList/show",
        }),
        submit(close = true) {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    this.loadingButton = true;
                    this.update(this.form)
                        .then((res) => {
                            this.loadingButton = false;
                            this.$alert(res);
                            this.parent().listChanged();
                            if (res.status == 200) this.close();
                        })
                        .catch((err) => {
                            this.loadingButton = false;
                            this.$alert(err);
                        });
                }
            });
        },
    },
};
</script>
